@import url("//fonts.googleapis.com/css2?family=Roboto+Condensed:wght@400;700&display=swap");
@import url("//fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");
@import "custom";
@import "~bootstrap/scss/bootstrap.scss";

::selection {
  color: white;
  background: $secondary;
}

// Add support for modal open over top of another modal.
.modal-backdrop:nth-of-type(2n) {
  z-index: 1055;
}
.modal:nth-of-type(2n) {
  z-index: 1056;
}
